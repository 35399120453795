import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle03,
  CAnchorNav,
  CCard02,
  CTable02,
  LContact03,
} from '../../../components/_index';
import stayPlanGet from '../../../utils/stayPlan-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const stayPlanData = stayPlanGet().filter((item: any) => {
    return item.node.category.includes('記念日・プロポーズ');
  });

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        type="ja"
        data={{
          title: {
            main: 'バルーンデコレーション',
            sub: <>BALLOON DECORATION</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/anniversary/balloon_anniversary/kv.png',
              },
              imgSp: {
                src: '/assets/images/anniversary/balloon_anniversary/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <div className="l_sect02 u_pb0">
        <LWrap>
          <p className="c_headingLv2 u_mb10">
            人生の記念日をお祝いするバルーンデコレーション
          </p>
          <p className="c_sectLead u_mb40">
            お誕生日や記念日のお祝い、プロポーズなどご要望にそったバルーンデコレーションをご提案させていただきます。
          </p>
          <CAnchorNav
            exClass="u_mb0 u_mb100"
            data={[
              {
                title: <>プロポーズプラン</>,
                link: {
                  href: '/anniversary/balloon_anniversary/#propose',
                },
              },
              {
                title: <>バースデープラン</>,
                link: {
                  href: '/anniversary/balloon_anniversary/#birthday',
                },
              },
            ]}
          />
        </LWrap>
      </div>
      <section>
        <LWrap>
          <div id="propose" className="u_pt100">
            <CSectTitle03 title="プロポーズプラン" />
            <CCard02
              col={3}
              exClass="u_mb0"
              data={[
                {
                  img: {
                    src: '/assets/images/anniversary/balloon_anniversary/img_balloon_anniversary.png',
                    alt: '',
                  },
                  title: <>プロポーズプラン A</>,

                  text: (
                    <>
                      <p>
                        ロイヤルパークホテルのロゴであるバラのつぼみを大きく開花させたバルーンがお部屋を豪華に彩ります。バラのようにおふたりの未来に大輪の花が開くことを願って装飾させていただきます。
                      </p>
                      <p className="u_fwb u_mt10">プラン料金:20,000円</p>
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/anniversary/balloon_anniversary/img_balloon_anniversary02.png',
                    alt: '',
                  },
                  title: <>プロポーズプラン B</>,

                  text: (
                    <>
                      <p>
                        淡いピンクのバルーンとバラの花びらがベッドの上に降り注がれ、大きな｢love｣をやさしく包み込みます。言葉では言い尽くせない愛をたくさんのバルーンと1本のバラに込めて、素敵な空間を演出いたします。
                      </p>
                      <p className="u_fwb u_mt10">プラン料金:28,000円</p>
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/anniversary/balloon_anniversary/img_balloon_anniversary03.png',
                    alt: '',
                  },
                  title: <>プロポーズプラン C</>,

                  text: (
                    <>
                      <p>
                        たくさんのバルーンが「MARRY
                        ME」の文字の背中を押すように華やかに演出いたします。愛する人へ伝えるその時【愛情、感謝、決意】たくさんの想いが溢れます。大切な日だからこそ、しっかりと想いが伝わりますよう心を込めてお作りいたします。
                      </p>
                      <p className="u_fwb u_mt10">プラン料金:45,000円</p>
                    </>
                  ),
                },
              ]}
            />
          </div>
          <div id="birthday" className="u_pt100">
            <CSectTitle03 title="バースデープラン" />
            <CCard02
              col={3}
              exClass="u_mb0"
              data={[
                {
                  img: {
                    src: '/assets/images/anniversary/balloon_anniversary/img_balloon_anniversary04.png',
                    alt: '',
                  },
                  title: <>バースデープラン スタンダード</>,

                  text: (
                    <>
                      <p>
                        タオルアートの白鳥は支え合うおふたりをイメージし、お花が咲いているように束ねられたバルーンはお誕生日の方を心から祝福していることを表現しています。大好きな人に送りたいと思っていただけるような空間をお作りいたします。
                      </p>
                      <p className="u_fwb u_mt10">プラン料金:23,000円</p>
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/anniversary/balloon_anniversary/img_balloon_anniversary05.png',
                    alt: '',
                  },
                  title: <>バースデープラン 1st バースデー</>,

                  text: (
                    <>
                      <p>
                        これから歩んでいく未来が明るくカラフルに彩りますようにという願いを込めて装飾いたします。さらに1年後、5年後、10年後、この大きな数字が刻まれていくことを夢に見てお部屋を可愛く彩らせていただきます。
                      </p>
                      <p className="u_fwb u_mt10">プラン料金:20,000円</p>
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/anniversary/balloon_anniversary/img_balloon_anniversary06.png',
                    alt: '',
                  },
                  title: <>バースデープラン 60th バースデー</>,
                  text: (
                    <>
                      <p>
                        ゴールドの60の数字を引き立たせてくれる上品な赤を組み合わせた装飾です。還暦を迎える方へ今までの感謝とこれからも健康でい続けてほしいという家族の願いを、鶴の背中に乗せて届くように願いながら装飾いたします。
                      </p>
                      <p className="u_fwb u_mt10">プラン料金:25,000円</p>
                    </>
                  ),
                },
              ]}
            />
          </div>
          <div className="l_sect">
            <CSectTitle03 title="ご注意事項" />
            <CTable02
              data={[
                {
                  title: 'ご注文方法',
                  content: <>ご宿泊予定日の1週間前までに、お電話にて要ご予約</>,
                },
                {
                  title: '装飾可能時間',
                  content: <>12:30～20:30</>,
                },
                {
                  title: 'その他ご注意事項',
                  content: (
                    <>
                      <ul className="c_circleList">
                        <li>
                          クリスマスや年末年始はご注文の締切や料金が異なります。お早めにご検討ください。
                        </li>
                        <li>掲載の写真は実際と異なる場合がございます。</li>
                        <li>キャンセル：装飾日の3日前20:00まで</li>
                      </ul>
                      <ul className="c_noteList u_mt10">
                        <li>料金には消費税が含まれます。</li>
                        <li>特別企画商品のため、各種特典・割引対象外です。</li>
                      </ul>
                    </>
                  ),
                },
              ]}
            />
          </div>
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'アニバーサリー',
              path: '/anniversary/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LContact03
        title={'ご予約・お問合せ'}
        btn={{
          btn: {
            label: 'お問合せフォーム',
            link: {
              href: 'https://contact.royalparkhotels.co.jp/rph/contact?_ifbs-rph_contact_form=s1_Step1',
              blank: true,
            },
          },
        }}
        tel={{
          number: '03-5641-2958',
          note: '宿泊営業課（受付時間 平日 8:00～20:00／土・日・祝日 9:00～18:00）',
        }}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
